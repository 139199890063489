<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('TBS-Digitalisation-grant-website-banner_991-x-331_C-1140x380.jpg');
          background-size: contain;
        "
      >
        <div
          style="    vertical-align: bottom;
          height: 142px;
    padding: 50px 15px;"
        ></div>
      </div>
    </div>

    <div class="container sales-enquiry">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <form
              class="form-inquiry"
              method="post"
              action="http://tbs.com.my/testing2/index.php?route=api/custom/smegrantenquiry"
            >
              <h1 style="    text-align: center;color: green;">
                {{ $route.query.m }}
              </h1>
              <div class="form-group row">
                <div class="col-sm-3">
                  Full Name<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="name"
                    name="fullname"
                    required=""
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3">
                  Email<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="email"
                    name="email"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Company Name<span class="acs-red">*</span>
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="company_name"
                    name="companyname"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Phone Number<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="telephone"
                    name="phonenumber"
                    required=""
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3">State<span class="acs-red">*</span>:</div>
                <div class="col-sm-9">
                  <select class="form-control input-sm" name="zone_id">
                    <option value="Johor">Johor</option
                    ><option value="Kedah">Kedah</option
                    ><option value="Kelantan">Kelantan</option
                    ><option value="Melaka">Melaka</option
                    ><option value="Negeri Sembilan">Negeri Sembilan</option
                    ><option value="Pahang">Pahang</option
                    ><option value="Perak">Perak</option
                    ><option value="Perlis">Perlis</option
                    ><option value="Pulau Pinang">Pulau Pinang</option
                    ><option value="Sabah">Sabah</option
                    ><option value="Sarawak">Sarawak</option
                    ><option value="Selangor">Selangor</option
                    ><option value="Terengganu">Terengganu</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Solutions<span class="acs-red">*</span>:
                </div>
                <div class="col-sm-9">
                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="solutions[]"
                        value="Electronic Point of Sale System (e-POS)"
                      />
                      Electronic Point of Sale System (e-POS)
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="solutions[]"
                        value="Procurement"
                      />
                      Procurement
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="solutions[]"
                        value="ERP / Accounting"
                      />
                      ERP / Accounting
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="solutions[]"
                        value="HR / Payroll system / CRM"
                      />
                      HR / Payroll system / CRM
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="solutions[]"
                        value="Digital Marketing / Sales"
                      />
                      Digital Marketing / Sales
                    </label>
                  </div>
                  <div id="checkbox-errors"></div>
                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-3 col-sm-9">
                  <p>
                    <small class="acs-red">* Required field</small>
                  </p>
                  <button
                    id="btn_submit"
                    data-sending="Sending"
                    data-submit="Submit"
                    type="submit"
                    class="btn btn-outline-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <img src="customer check list website_page-0001.jpg" alt="" />

            <h3 style="text-align: center; ">
              Refer&nbsp;&nbsp;<a
                href="https://www.mybsn.com.my/content.xhtml?contentId=1561"
                target="_blank"
                >SME Digitalisation Initiative</a
              >&nbsp;for more information.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Enquiry"
};
</script>
